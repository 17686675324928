import { css } from '@emotion/core';
import styled from '@emotion/styled';

const heyPollsFigureStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  position: relative;
  width: 100%;
  border-radius: 40px 40px 5px 5px;
  display: grid;
  grid-template-columns: 1fr;
  background-color: ${color.grey50};
  ${breakpoint.fromPhoneMedium} {
    border-radius: 30px 30px 30px 5px;
    margin-top: 210px;
    height: 260px;
  }
  ${breakpoint.min730} {
    margin-top: 0;
    border-radius: 150px 150px 150px 25px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 130px);
  }
`;

const imageWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  ${breakpoint.fromPhoneMedium} {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  ${breakpoint.min730} {
    margin: initial;
    position: initial;
    margin-left: 85px;
    max-width: 400px;
    transform: translateY(-167px);
  }
`;

const pollWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: none;
  ${breakpoint.min730} {
    display: block;
    grid-column: 2;
    grid-row: 1 / span 2;
  }
`;

/**
 * Component style declaration
 */
export const HeyPollsFigure = styled('div')(heyPollsFigureStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
export const PollWrapper = styled('div')(pollWrapperStyles);
