/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Button, TextAccent } from '../../components';
import { StoryCardStyles, Content } from './StoryCard.style';
import {
  WorkFigure,
  ScrumSpace,
  HLTCorp,
  VirtualCooperation,
  Panoram,
  SKGaming,
  RDesktop,
  Iten,
} from './StoryCardWork.style';
import card from '../../images/xl-card.svg';
import sineWave from '../../images/tile-sine-wave.mp4';
import sineWaveSVG from '../../images/tile-sine-wave.svg';

export const tileImageWebP = graphql`
  fragment tileImageWebP on File {
    childImageSharp {
      fluid(maxWidth: 350) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

/**
 * Home: StoryCardWork composition
 */
const StoryCardWork = () => {
  const data = useStaticQuery(graphql`
    query {
      scrumspace: file(relativePath: { eq: "tile-scrumspace.jpg" }) {
        ...tileImageWebP
      }
      virtualcooperation: file(relativePath: { eq: "tile-virtual-cooperation.png" }) {
        ...tileImageWebP
      }
      hltcorp: file(relativePath: { eq: "tile-hltcorp.jpg" }) {
        ...tileImageWebP
      }
      hltcorpMobile: file(relativePath: { eq: "tile-hltcorp.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      skgaming: file(relativePath: { eq: "tile-sk-gaming.jpg" }) {
        ...tileImageWebP
      }
      rdesktop: file(relativePath: { eq: "tile-rdesktop.png" }) {
        ...tileImageWebP
      }
      panoram: file(relativePath: { eq: "tile-panoram.png" }) {
        ...tileImageWebP
      }
    }
  `);

  const hltCorpSources = [
    data.hltcorpMobile.childImageSharp.fluid,
    {
      ...data.hltcorp.childImageSharp.fluid,
      media: `(min-width: 730px)`,
    },
  ];

  return (
    <StoryCardStyles>
      <WorkFigure>
        <ScrumSpace>
          <div className="imageWrapper">
            <Img
              fluid={data.scrumspace.childImageSharp.fluid}
              loading="eager"
              imgStyle={{ objectFit: 'cover' }}
              alt="Image of Scrumspace project"
            />
          </div>
          <div className="card">
            <img src={card} alt="XL Card" />
          </div>
        </ScrumSpace>
        <VirtualCooperation>
          <div className="imageWrapper">
            <Img
              fluid={data.virtualcooperation.childImageSharp.fluid}
              loading="eager"
              alt="Image of Virtual Cooperation project"
            />
          </div>
        </VirtualCooperation>
        <Iten>
          <video poster={sineWaveSVG} width="520" height="108" preload={true} autoPlay={true} loop={true} muted={true}>
            <source src={sineWave} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Iten>
        <RDesktop>
          <div className="imageWrapper">
            <Img fluid={data.rdesktop.childImageSharp.fluid} loading="eager" alt="Image of RDesktop project" />
          </div>
        </RDesktop>
        <HLTCorp>
          <div className="imageWrapper">
            <Img fluid={hltCorpSources} loading="eager" alt="Image of HLT Corp project" />
          </div>
        </HLTCorp>
        <Panoram>
          <div className="imageWrapper">
            <Img fluid={data.panoram.childImageSharp.fluid} loading="eager" alt="Image of Panoram project" />
          </div>
        </Panoram>
        <SKGaming>
          <div className="imageWrapper">
            <Img fluid={data.skgaming.childImageSharp.fluid} loading="eager" alt="Image of SK Gaming project" />
          </div>
        </SKGaming>
      </WorkFigure>
      <Content>
        <TextAccent withSpacing>
          <strong>Work experience</strong> — I enjoy building beautiful and performant experiences.
        </TextAccent>
        <TextAccent withSpacing>
          I’ve been fortunate enough to work on many exciting projects and technologies for web and mobile platforms.
        </TextAccent>
        <Button to="/work" title="Projects I’ve worked on" />
      </Content>
    </StoryCardStyles>
  );
};

export default StoryCardWork;
