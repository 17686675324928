/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TextAccent, Link, ArrowExternal, Button } from '../../components';
import { StoryCardStyles, Content } from './StoryCard.style';
import HeyPollsPollFeature from './HeyPollsPollFeature';
import { HeyPollsFigure, ImageWrapper, PollWrapper } from './StoryCardHeyPolls.style';

export const tileImage = graphql`
  fragment tileImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

/**
 * Home: StorycardHeyPolls composition
 */
const StoryCardHeyPolls = () => {
  const data = useStaticQuery(graphql`
    query {
      heypolls: file(relativePath: { eq: "tile-hey-polls.png" }) {
        ...tileImage
      }
      question: file(relativePath: { eq: "hey-polls-question.png" }) {
        ...tileImage
      }
      answer: file(relativePath: { eq: "hey-polls-answer.png" }) {
        ...tileImage
      }
    }
  `);

  return (
    <StoryCardStyles>
      <HeyPollsFigure>
        <ImageWrapper>
          <Img
            fluid={data.heypolls.childImageSharp.fluid}
            imgStyle={{ objectFit: 'cover' }}
            alt="Image of Hey, Polls! logo on mobile phone"
          />
        </ImageWrapper>
        <PollWrapper>
          <HeyPollsPollFeature />
        </PollWrapper>
      </HeyPollsFigure>
      <Content>
        <TextAccent withSpacing>
          <strong>Hey, Polls!</strong> — Create, share and personalise polls effortlessly. Making a choice has never
          been so easy before.
        </TextAccent>
        <TextAccent withSpacing>
          Check out the{' '}
          <Link to="https://heypolls.com/">
            web
            <ArrowExternal />
          </Link>{' '}
          and{' '}
          <Link to="https://play.google.com/store/apps/details?id=app.heypolls">
            Android application
            <ArrowExternal />
          </Link>
          .
        </TextAccent>
        <Button to="/blog/heypolls" title="Interesting project details" />
      </Content>
    </StoryCardStyles>
  );
};

export default StoryCardHeyPolls;
