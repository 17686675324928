import { css } from '@emotion/core';
import styled from '@emotion/styled';

const headerStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-top: 75px;
  margin-bottom: 75px;
  display: grid;
  ${'' /* // eslint-disable-next-line prettier/prettier */}
  grid-template-areas: "main" "nav";
  ${breakpoint.min730} {
    justify-content: space-between;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 333px);
    ${'' /* // eslint-disable-next-line prettier/prettier */}
    grid-template-areas: "main nav";
    min-height: calc(60vh - 65px - 75px);
  }
  ${breakpoint.fromDesktop} {
    margin-top: 140px;
    min-height: calc(60vh - 140px);
  }
`;

const mainStyles = css`
  grid-area: main;
`;

const navStyles = css`
  grid-area: nav;
`;

/**
 * Component style declarations
 */
export const Header = styled('header')(headerStyles);
export const Main = styled('div')(mainStyles);
export const Nav = styled('nav')(navStyles);
