/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import {
  Wrapper,
  Creator,
  QuestionBubble,
  Voter,
  AnswerBubble,
  AnswerFooter,
  ButtonOption,
  ButtonSubmit,
  ResultsWrapper,
  ResultsList,
  ResultsItem,
  Progress,
} from './HeyPollsPollFeature.style';
import emoji1 from '../../images/emoji-01.svg';
import emoji2 from '../../images/emoji-02.svg';

/**
 * Home: HeyPollsPollFeature composition
 */
const HeyPollsPollFeature = () => {
  const progresValues = ['1:00 PM', '1:30 PM'];
  const votes = {
    one: ['Joe', 'Molly', 'Lolita', 'Mark'],
    two: ['Michael', 'John', 'Valentina', 'Tim'],
  };

  const [selected, setSelected] = useState(null);
  const [results, setResults] = useState(votes);
  const [resultsVisible, setResultsVisibility] = useState(false);
  const handleVoteClick = voteOption => {
    setSelected(voteOption);
    const newResults = {
      ...votes,
      [voteOption]: [...votes[voteOption], 'Curious visitor'],
    };

    setResults(newResults);
  };

  const calculatePercentage = option => {
    const totalVoters = Object.values(results).reduce((acc, value) => {
      return acc + value.length;
    }, 0);
    const optionVoterCount = results[option].length;

    return Math.floor((optionVoterCount / totalVoters) * 100);
  };

  return (
    <Wrapper>
      <Creator>
        <img src={emoji1} alt="Emoji of poll creator" className="emoji" />
        <QuestionBubble>
          Okey folks, we need to make this decision, and we have to make it now! When should we go for lunch today?
        </QuestionBubble>
      </Creator>
      <Voter resultsVisible={resultsVisible}>
        <AnswerBubble resultsVisible={resultsVisible}>
          {!resultsVisible ? (
            <Fragment>
              <ButtonOption type="button" onClick={() => handleVoteClick('one')} selected={selected === 'one'}>
                1:00 PM
              </ButtonOption>
              <ButtonOption type="button" onClick={() => handleVoteClick('two')} selected={selected === 'two'}>
                1:30 PM
              </ButtonOption>
              <AnswerFooter>
                <ButtonSubmit onClick={() => setResultsVisibility(!resultsVisible)}>Submit your vote</ButtonSubmit>
              </AnswerFooter>
            </Fragment>
          ) : (
            <ResultsWrapper>
              <span>Great, thank you for voting!</span>
              <ResultsList>
                {Object.keys(results).map((item, index) => (
                  <ResultsItem key={results[item]}>
                    <Progress
                      option={progresValues[index]}
                      voters={results[item]}
                      percentage={calculatePercentage(item)}
                    />
                  </ResultsItem>
                ))}
              </ResultsList>
            </ResultsWrapper>
          )}
        </AnswerBubble>
        {resultsVisible ? (
          <ButtonSubmit onClick={() => setResultsVisibility(!resultsVisible)} className="reset">
            Change your vote
          </ButtonSubmit>
        ) : null}
        <img src={emoji2} alt="Emoji of poll voter" className="emoji" />
      </Voter>
    </Wrapper>
  );
};

export default HeyPollsPollFeature;
