/** @jsx jsx */
import { jsx } from '@emotion/core';
import Page from '../templates/Page';
import { fadeInUp150 } from '../utils/classes';
import { SEO, StorytellingSection } from '../components';
import PageHeader from '../compositions/Home/PageHeader';
import StoryCardAbout from '../compositions/Home/StoryCardAbout';
import StoryCardWork from '../compositions/Home/StoryCardWork';
import StoryCardHeyPolls from '../compositions/Home/StoryCardHeyPolls';

/**
 * Homepage
 */
const Homepage = () => {
  return (
    <Page>
      <SEO title="Danijel Grabež | Web Development & Product Design" />
      <PageHeader />
      <div css={fadeInUp150}>
        <StorytellingSection offsetKind="container">
          <StoryCardAbout />
          <StoryCardWork />
          <StoryCardHeyPolls />
        </StorytellingSection>
      </div>
    </Page>
  );
};

export default Homepage;
