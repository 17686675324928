/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link, Heading, List, ListItem, TextAccent } from '../../components';
import { mt, dib } from '../../utils/classes';
import { Header, Main, Nav } from './PageHeader.style';

/**
 * Home: PageHeader composition
 */
const PageHeader = () => (
  <Header>
    <Main>
      <Link to="/" linkKind="no-underline" css={dib}>
        <Heading element="h1" colorKind="brand">
          Danijel Grabež
        </Heading>
      </Link>
      <Heading element="h2" weight={400} colorKind="neutral">
        Software developer and <br />
        product designer
      </Heading>
    </Main>
    <Nav>
      <List listKind="unstyled">
        <ListItem>
          <TextAccent element="span" colorKind="neutral">
            You can contact me via email{' '}
          </TextAccent>
          <Link
            to="mailto:danijel.grabez@gmail.com?subject=Let’s%20work%20together
          "
          >
            danijel.grabez@gmail.com
          </Link>
          .
        </ListItem>
        <ListItem css={mt(25)}>
          <TextAccent element="span" colorKind="neutral">
            For social insights, you can reach me on <Link to="https://github.com/danijelgrabez">GitHub</Link>,{' '}
            <Link to="https://twitter.com/danijel_grabez">Twitter</Link>, or{' '}
            <Link to="https://www.linkedin.com/in/danijelgrabez">LinkedIn</Link>.
          </TextAccent>
        </ListItem>
      </List>
    </Nav>
  </Header>
);

export default PageHeader;
