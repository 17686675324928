import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const xlCard = keyframes`
  0% { transform: rotate(0) translateY(30px); }
  25% { transform: rotate(10deg) translateY(0); }
  50% { transform: rotate(0) translateY(15px); }
  75% { transform: rotate(10deg) translateY(0); }
  100% { transform: rotate(0) translateY(30px); }
`;

const workFigureStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  position: relative;
  width: 100%;
  background-color: ${color.white};
  display: grid;
  grid-template-rows: repeat(3, 230px);
  ${breakpoint.fromPhoneSmall} {
    grid-template-rows: repeat(3, 260px);
    grid-template-rows: repeat(3, 230px);
  }
  ${breakpoint.fromPhoneMedium} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 260px);
  }
  ${breakpoint.min730} {
    height: 260px;
    grid-template-columns: repeat(5, 260px);
    grid-template-rows: repeat(2, 130px);
    background-color: ${color.grey900Transparent15};
  }
`;

const scrumSpaceStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  background-color: ${color.white};
  position: relative;
  overflow: hidden;
  grid-column: 1 / -1;
  ${breakpoint.fromPhoneMedium} {
    grid-column: 1 / auto;
    grid-row: 1 / auto;
  }
  ${breakpoint.min730} {
    grid-column: 1 / 2;
    grid-row: 1 / span 2;
    overflow: visible;
  }

  .card {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100px;
    animation: ${xlCard} 10s ease-in-out infinite;
    animation-fill-mode: forwards;
    ${breakpoint.min730} {
      left: -60px;
    }
  }
`;

const hltCorpStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  background: ${work.hltCorp};
  grid-column: 1 / -1;
  grid-row: 2;
  position: relative;
  overflow: hidden;
  ${breakpoint.fromPhoneMedium} {
    grid-row: 3;
  }
  ${breakpoint.min730} {
    grid-column: 2;
    grid-row: 2;
  }

  .imageWrapper {
    ${'' /* < 729.98px */}
    @media screen and (max-width: 45.623em) {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    ${'' /* < 460px */}
    @media screen and (max-width: 28.75em) {
      right: 0;
      width: 140%;
    }
    ${breakpoint.toPhoneSmall} {
      bottom: 0;
      width: 160%;
    }
  }
`;

const virtualCooperationStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  display: none;
  background-color: ${work.virtualCooperation};
  position: relative;
  grid-column: 1;
  grid-row: 2;
  ${breakpoint.min730} {
    grid-column: 2;
    grid-row: 1;
  }
  ${breakpoint.fromPhoneMedium} {
    display: block;
  }

  .imageWrapper {
    max-width: 240px;
    margin: 0 auto;
    /* stylelint-disable */
    transform: translateY(calc(-100% + 220px));
    ${breakpoint.min730} {
      transform: translateY(calc(-100% + 118px));
      /* stylelint-enable */
    }
  }
`;

const panoramStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  display: none;
  background-color: ${work.panoram};
  grid-column: 2;
  grid-row: 2 / span 1;
  ${breakpoint.fromPhoneMedium} {
    display: block;
  }
  ${breakpoint.min730} {
    grid-column: 3;
    grid-row: 2;
  }

  .imageWrapper {
    margin: 0 auto;
    width: 100%;
    /* stylelint-disable */
    transform: translateY(calc(-100% + 260px));
    ${breakpoint.min730} {
      width: 220px;
      transform: translateY(calc(-100% + 130px));
      /* stylelint-enable */
    }
  }
`;

const skGamingStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  display: none;
  position: relative;
  overflow: hidden;
  background-color: ${work.skGaming};
  grid-column: 1;
  grid-row: 4;
  ${breakpoint.fromPhoneMedium} {
    display: block;
  }
  ${breakpoint.min730} {
    grid-column: 4;
    grid-row: 2;
  }

  .imageWrapper {
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    ${breakpoint.min730} {
      width: 165px;
      position: initial;
    }
  }
`;

const rdesktopStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  background-color: ${work.rDesktop};
  position: relative;
  grid-column: 1 / -1;
  grid-row: 3;
  ${breakpoint.fromPhoneMedium} {
    grid-column: 2;
    grid-row: 4;
  }
  ${breakpoint.min730} {
    grid-row: span 2;
    grid-column: auto;
  }

  .imageWrapper {
    width: 100%;
    max-width: 320px;
    margin: 10px auto auto;
    ${breakpoint.min730} {
      position: absolute;
      width: 360px;
      left: -8px;
      top: -2px;
    }
  }
`;

const itenStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  display: none;
  background-color: ${color.white};
  grid-column: span 2;
  grid-row: 1;
  ${breakpoint.min730} {
    display: block;
  }
`;

/**
 * Component style declaration
 */
export const WorkFigure = styled('div')(workFigureStyles);
export const ScrumSpace = styled('div')(scrumSpaceStyles);
export const HLTCorp = styled('div')(hltCorpStyles);
export const VirtualCooperation = styled('div')(virtualCooperationStyles);
export const Panoram = styled('div')(panoramStyles);
export const SKGaming = styled('div')(skGamingStyles);
export const RDesktop = styled('div')(rdesktopStyles);
export const Iten = styled('div')(itenStyles);
