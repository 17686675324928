import React from 'react';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const yAxis = (side = 'left') => keyframes`
  50% { transform: ${side === 'left' ? 'translateY(-150px)' : 'translateY(-50px)'}; }
  100% { transform: ${side === 'left' ? 'translateY(-50px)' : 'translateY(0)'}; }
`;

const xAxis = (side = 'left') => keyframes`
  50% { transform: ${side === 'left' ? ' translateX(35px)' : ' translateX(-10px)'}; }
  100% { transform: ${side === 'left' ? ' translateX(35px)' : ' translateX(-35px)'}; }
`;

const grow = keyframes`
  75% { transform: scale(1); }
  100% { transform: scale(1); }
`;

const aboutImageStyles = ({ theme: { about } }) => css`
  background: ${about.tileBackground};
  height: 260px;
  overflow: hidden;
  position: relative;
`;

const delay = {
  circleOne: {
    grow: '1s',
    x: '1s',
    y: '2s',
  },
  circleTwo: {
    grow: '1s',
    x: '1s',
    y: '2s',
  },
};

const circleOneBaseStyles = ({ theme: { about } }) => css`
  display: block;
  width: 750px;
  height: 750px;
  border-radius: 50%;
  background-color: ${about.circleOne};
  transform: scale(0);
  animation: ${grow} 5s cubic-bezier(0.02, 0.01, 0.21, 1) ${delay.circleOne.grow};
  animation-fill-mode: forwards;
`;

const circleOneMoveXStyles = css`
  animation: ${xAxis()} 6s cubic-bezier(0.02, 0.01, 0.21, 1) ${delay.circleOne.x};
  animation-fill-mode: forwards;
`;

const circleOneMoveYStyles = css`
  animation: ${yAxis()} 5s cubic-bezier(0.3, 0.27, 0.07, 1.64) ${delay.circleOne.y};
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
  top: 0;
`;

const circleTwoBaseStyles = ({ theme: { about } }) => css`
  display: block;
  width: 750px;
  height: 750px;
  border-radius: 50%;
  background-color: ${about.circleTwo};
  transform: scale(0);
  animation: ${grow} 6s cubic-bezier(0.02, 0.01, 0.21, 1) ${delay.circleTwo.grow};
  animation-fill-mode: forwards;
  opacity: 0.7;
`;

const circleTwoMoveXStyles = css`
  animation: ${xAxis('right')} 4.5s cubic-bezier(0.02, 0.01, 0.21, 1) ${delay.circleTwo.x};
  animation-fill-mode: forwards;
  position: absolute;
  right: 0;
  bottom: -40px;
  mix-blend-mode: multiply;
`;

const circleTwoMoveYStyles = css`
  animation: ${yAxis('right')} 4s cubic-bezier(0.3, 0.27, 0.07, 1.64) ${delay.circleTwo.y};
  animation-fill-mode: forwards;
`;

/**
 * Component style declaration
 */
export const AboutFigure = styled('div')(aboutImageStyles);
const CircleOneBase = styled('span')(circleOneBaseStyles);
const CircleOneMoveX = styled('div')(circleOneMoveXStyles);
const CircleOneMoveY = styled('div')(circleOneMoveYStyles);
const CircleTwoBase = styled('span')(circleTwoBaseStyles);
const CircleTwoMoveX = styled('div')(circleTwoMoveXStyles);
const CircleTwoMoveY = styled('div')(circleTwoMoveYStyles);

export const CircleOne = () => (
  <CircleOneMoveX>
    <CircleOneMoveY>
      <CircleOneBase />
    </CircleOneMoveY>
  </CircleOneMoveX>
);

export const CircleTwo = () => (
  <CircleTwoMoveX>
    <CircleTwoMoveY>
      <CircleTwoBase />
    </CircleTwoMoveY>
  </CircleTwoMoveX>
);
