/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, TextAccent } from '../../components';
import { StoryCardStyles, Content } from './StoryCard.style';
import { AboutFigure, CircleOne, CircleTwo } from './StoryCardAbout.style';

/**
 * Home: StoryCardAbout composition
 */
const StoryCardAbout = () => (
  <StoryCardStyles>
    <AboutFigure>
      <CircleOne />
      <CircleTwo />
    </AboutFigure>
    <Content>
      <TextAccent withSpacing>
        <strong>Where can I help</strong> — I help companies and small businesses thrive by advising, designing and
        developing digital products.
      </TextAccent>
      <TextAccent withSpacing>I specialise in web and mobile products.</TextAccent>
      <Button to="/about" title="Scope of expertise" />
    </Content>
  </StoryCardStyles>
);

export default StoryCardAbout;
