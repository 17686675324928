import { css } from '@emotion/core';
import styled from '@emotion/styled';

const storyCardStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) =>
  css`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 75px;
    ${breakpoint.min730} {
      width: 1300px;
      min-width: 1300px;
      margin-left: 100px;
      margin-bottom: 0;
    }
    &:first-of-type {
      margin-left: 0px;
      ${'' /* > 1290px and 600px */}
      @media screen and (min-width: 80.625em) and (min-height: 37.5em) {
        margin-left: -150px;
      }
    }
  `;

const contentCardStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-top: 40px;
  ${'' /* > 1290px and > 600px */}
  @media screen and (min-width: 80.625em) and (min-height: 37.5em) {
    margin-left: 150px;
  }
  ${breakpoint.fromPhoneMedium} {
    max-width: 500px;
  }
`;

/**
 * Component style declaration
 */
export const StoryCardStyles = styled('div')(storyCardStyles);
export const Content = styled('header')(contentCardStyles);
